<template>
  <div class="page-container">
    <page-header></page-header>
    <page-table ref="table" show-paginate :get-data="userPage" :fields="fields" :search-model="searchModel"
                :item-actions="actions" @onActionClick="handleItemActionClick">
      <template slot="search-items">
        <el-form-item label="归属组织" prop="developersName">
          <el-input size="small" v-model="searchModel.developersName" placeholder="归属组织"></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="webSearchRoleId">
          <el-select size="small" v-model="searchModel.webSearchRoleId" placeholder="角色">
            <el-option :label="type.name" :value="type.id" v-for="type in roles" :key="type.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账号状态" prop="webSearchAuthOver">
          <el-select size="small" v-model="searchModel.webSearchAuthOver" placeholder="账号状态">
            <el-option :label="type.label" :value="type.value" v-for="type in userState" :key="type.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色信息" prop="webSearch">
          <el-input size="small" v-model="searchModel.webSearch" placeholder="姓名 手机号"></el-input>
        </el-form-item>
      </template>
    </page-table>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader"
import PageTable from "@/components/PageTable"
import {userPage,roleAll} from "@/api/common"
import {APP_NAME, USER_STATE} from "@/utils/config";

export default {
  name: "Authorities",
  data() {
    return {
      searchModel: {
        developersName:'',
        webSearchRoleId:'',
        webSearchAuthOver:'',
        webSearch:"",
      },
      roles:[],
      userState:USER_STATE,
      userPage: userPage,
      fields: [
        {label: 'UserID', key: 'id',width: 180},
        {label: '姓名', key: 'name', width: 130},
        {
          label: '性别', width: 60, render({gender}, h) {
            return h('div', gender == 0 ? '男' : (gender == 1 ? '女' : ''))
          }
        },
        {label: '手机号', key: 'phone', width: 120,},
        {
          label: '用户类型',
          width: 110,
          render({userType}, h) {
            let types = {
              admin: '集团用户',
              enterprise: '企业用户',
              distributor: '经销商',
            }
            if (types[userType] !== undefined) {
              return h('div', types[userType])
            }
            return h('div')
          }
        },
        {
          label: '归属组织',
          render: ({developers, distributor, userType}, h) => {
            if (!userType) {
              return h("span", '')
            } else if (userType == 'admin') {
              return h("span", APP_NAME)
            } else if (userType == 'enterprise') {
              return h("span", developers.name)
            } else if (userType == 'distributor') {
              return h("span", distributor.name)
            }
          }
        },
        {
          label: '角色权限',  width: 200,
          render({roleList},h) {
            let str="";
            if(roleList!=null){
              for (let roleListElement of roleList) {
                if(str===""){
                  str+=roleListElement.name;
                }else {
                  str+=","+roleListElement.name;
                }
              }
            }
            return h('span',str);
          }
        },
        {
          label: '业务权限',
          render:({developersList,distributorList}) =>{
            let developerName="";
            let str="";
            if(developersList!=null){
              for (let roleListElement of developersList) {
                if(str===""){
                  developerName=roleListElement.name;
                  str+=roleListElement.name;
                }else {
                  str+="，"+roleListElement.name;
                }
              }
            }
            let one=true;
            if(distributorList!=null&&distributorList.length>0){
              str+=developerName!==""?"-":""
              for (let roleListElement of distributorList) {
                if(one){
                  if(developerName===""){
                    developerName=roleListElement.name;
                  }
                  str+=roleListElement.name;
                  one=false
                }else {
                  str+="，"+roleListElement.name;
                }
              }
            }
            return <el-tooltip placement="right-end">
              <div slot="content"><div class="tip-box"> {str}</div></div>
              <span>{developerName}</span>
            </el-tooltip>
          }
        },
        {
          label: '账号状态',   width: 110,
          render: ({isSalesman,isAuthOver},h) => {
            if(isSalesman){
              if(isAuthOver){
                return (<el-tag type="success" color="#E8F8E9" style="color: #19B21E;">已实名</el-tag>)
              } else {
                return (<el-tag type="warning" color="#FFF5E6" style="color: #FA9600;">未实名</el-tag>)
              }
            } else {
              return h('span', '')
            }
          }
        },
        {label: '创建时间', key: 'time', width: 180, align: 'center'},
      ],
      actions: [
        {action: 'editPermission', label: '编辑权限', type: 'primary',permission: 'qx:edit'},
      ],
    }
  },
  components: {
    PageHeader,
    PageTable
  },
  created() {
    this.getRoles();
  },
  mounted() {

  },
  activated() {
    this.$refs.table && this.$refs.table.loadData()
  },
  methods: {
    handleItemActionClick(action, item) {
      let that = this
      that[action + 'Action'](item)
    },
    editPermissionAction(item) {
      this.$router.push({path: '/admin/authority/' + item.id})
    },
    getRoles() {
      let that=this;
      roleAll().then(res=>{
        that.roles=res;
      }).catch(()=>{

      })
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
